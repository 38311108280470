<template>
  <!-- header -->
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-grid>
          <ion-row class="align-center" id="row">
            <ion-col size="9" class="d-flex">
              <ion-buttons class="mr-2" @click="$emit('close-modal')">
                <ion-icon size="small" :icon="arrowBackOutline"></ion-icon>
              </ion-buttons>
              <ion-label class="fs-4 fw-bold">{{ $t('payment') }}</ion-label>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-grid>
        <div class="pa-3">
          <!-- Fluid secsion -->
          <div class="card-item" v-if="isShowFluidPayment" @click="chooseCard({ card_type: 'fluid' })">
            <ion-row class="w-100">
              <ion-col size="2" class="d-flex align-center pl-3">
                <ion-img :src="FLUID_ICON" />
              </ion-col>
              <ion-col size="10" class="d-flex align-center">
                <ion-label>
                  {{ $t('fluid') }}
                </ion-label>
              </ion-col>
            </ion-row>
          </div>
          <div v-if="isShowFluidPayment" class="fluid-warning">
            <ion-row>
              <ion-col size="2"
                ><ion-icon color="warning" class="fs-6" :icon="alertCircleOutline"></ion-icon
              ></ion-col>
              <ion-col size="10">{{ $t('orderB2b.fluidWarning') }}</ion-col>
            </ion-row>
          </div>

          <div
            class="card-item mt-3"
            @click="chooseCard()"
            :class="{ 'disable-selection': isShowFluidPayment }"
          >
            <ion-row class="w-100">
              <ion-col size="2" class="d-flex align-center pl-3">
                <ion-icon :icon="cashOutline" class="fs-4"></ion-icon>
              </ion-col>
              <ion-col size="10" class="d-flex align-center">
                <ion-label>
                  {{ $t('credit_term') }}
                  ({{ `${customerView?.customer_credit_term.displayName}` }})
                </ion-label>
              </ion-col>
            </ion-row>
          </div>
          <div
            v-if="isShowStripeCards && mapStripeCard.length > 0"
            :class="{ 'disable-selection': isShowFluidPayment }"
          >
            <div
              class="card-item"
              v-for="(item, index) in mapStripeCard"
              :key="index"
              @click="chooseCard({ ...item, card_type: 'stripe' })"
            >
              <ion-row class="w-100">
                <ion-col size="2" class="d-flex align-center pl-3">
                  <ion-img :src="getCardImage(item.brand)" />
                </ion-col>
                <ion-col size="7" class="d-flex align-center">
                  <div class="d-flex flex-column">
                    <div>
                      <ion-label> {{ item.brand }} ({{ item.funding }}) </ion-label>
                    </div>
                    <div class="">
                      <ion-label color="grey5" class="fs-1"
                        >{{ item.last4 }} {{ item.exp_month }}/{{ item.exp_year }}</ion-label
                      >
                    </div>
                  </div>
                </ion-col>
                <ion-col size="3" class="d-flex align-center">
                  <div class="card-badge" v-if="item.isDefault">
                    <ion-label class="fw-500 text-white">{{ $t('default') }}</ion-label>
                  </div>
                </ion-col>
              </ion-row>
            </div>
          </div>
        </div>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { DEFAULT_CARD, FLUID_ICON } from '@/modules/sale/constants';
import { stripe } from '@/modules/sale/services/libs/payment';
import { ACTIONS as ACTIONS_PAYMENT } from '@/modules/sale/store/payment/actions';
import { arrowBackOutline, cashOutline } from 'ionicons/icons';
import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import { createNamespacedHelpers, useStore } from 'vuex';
const { mapActions: mapActionsPayment } = createNamespacedHelpers('sale/payment');

export default defineComponent({
  name: 'credit-card',
  inject: ['$storage'],
  emits: ['close-modal', 'choose-card'],
  props: {
    customerView: {
      type: Object,
      required: true
    },
    isShowFluidPayment: {
      type: Boolean,
      required: false
    }
  },

  setup() {
    const store = useStore();
    const storage = inject('$storage');
    const selectedCompany = ref(null);
    const getDefaultAndListStripeCard = async () => {
      await store.dispatch(`sale/payment/${ACTIONS_PAYMENT.GET_DEFAULT_CARD}`, selectedCompany.value.id);
      await store.dispatch(`sale/payment/${ACTIONS_PAYMENT.SALE_LIST_STRIPE_CARD}`, selectedCompany.value.id);
    };
    const listStripeCard = computed(() => store.getters[`sale/payment/saleListStripeCard`]);
    const defaultStripeId = computed(() => store.getters[`sale/payment/defaultCardId`]);
    const isShowStripeCards = computed(() => {
      return false;
      // if (!props.customerView) return false;
      // return TENANT_IDS.includes(props.customerView.tenant_id);
    });
    const mapStripeCard = computed(() => {
      if (listStripeCard.value && listStripeCard.value.data) {
        const cardData = listStripeCard.value.data.map((item) => ({
          ...item,
          isDefault: defaultStripeId?.value ? item.id === defaultStripeId.value : false
        }));
        cardData.sort((a, b) => (a.isDefault === b.isDefault ? 0 : a.isDefault ? -1 : 1));
        return cardData;
      } else {
        return [];
      }
    });
    onMounted(async () => {
      selectedCompany.value = await storage.getSelectedCompany();
      if (isShowStripeCards.value) await getDefaultAndListStripeCard();
    });
    return {
      mapStripeCard,
      isShowStripeCards,
      arrowBackOutline,
      cashOutline,
      FLUID_ICON
    };
  },

  methods: {
    ...mapActionsPayment([ACTIONS_PAYMENT.SALE_LIST_STRIPE_CARD]),
    getCardImage(brand) {
      return stripe.getCardImage(brand);
    },
    chooseCard(card = DEFAULT_CARD) {
      if (card.card_type !== 'fluid' && this.isShowFluidPayment) return;
      this.$emit('choose-card', card);
    }
  }
});
</script>

<style lang="scss" scoped>
.card-item {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background: #fff;
  height: 50px;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
#row {
  height: 50px;
}
.fluid-warning {
  background-color: #fdf5ed;
}
.disable-selection {
  position: relative;
  &::after {
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 50px;
    top: 0;
    background: black;
    opacity: 0.5;
  }
}
.card-badge {
  color: white;
  background: #04565a;
  padding: 3px 5px;
  font-size: 12px;
  border-radius: 5px;
}
</style>
