<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="pa-2">
        <ion-text class="font-style-modal-title">{{ $t('create_booking') }}</ion-text>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="pa-2">
        <div class="info-area d-flex flex-row">
          <ion-icon color="warning info-icon" :icon="informationCircleOutline"></ion-icon>
          <div class="d-flex flex-column">
            <ion-text class="fs-15px mb-2">{{ $t('unable_create_booking_title') }}</ion-text>
            <ion-text class="fs-13px fw-400">{{ $t('unable_create_booking_body') }}:</ion-text>
          </div>
        </div>
        <!-- not tagged items -->
        <div>
          <product-item-checkout
            v-for="item in notTaggedItemsSku"
            :key="item.id"
            :item="item"
          ></product-item-checkout>
        </div>
      </div>
    </ion-content>
    <ion-footer class="ion-no-border">
      <div class="grey-space space-height"></div>
      <ion-toolbar class="toolbar-white-area">
        <div class="fs-13px fw-400 text-center my-3 mx-2">{{ $t('unable_create_booking_message') }}</div>
        <ion-button class="green-expand-btn mx-2 mb-2" expand="block" @click="onOkAndBackToCart">{{
          $t('OK')
        }}</ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>
<script>
import ProductItemCheckout from '@/modules/sale/views/cart/components/ProductItemCheckout.vue';
import { informationCircleOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'BookingFailedInfo',
  props: {
    notTaggedItemsSku: {
      type: Array,
      default: () => []
    },
    onOkAndBackToCart: {
      type: Function,
      default: () => {}
    }
  },
  components: {
    ProductItemCheckout
  },
  setup() {
    return {
      informationCircleOutline
    };
  }
});
</script>
<style lang="scss" scoped>
.info-area {
  padding: 12px 16px;
  justify-content: flex-start;
  justify-items: start;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--primary-yellow-500, #eb8c31);
  background: var(--primary-yellow-25, #fdf5ed);
}
.info-icon {
  height: 32px;
  width: 32px;
  margin-top: -4px;
}
.space-height {
  height: 5px;
}
</style>
