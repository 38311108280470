<template>
  <ion-page>
    <!-- header -->
    <ion-header>
      <ion-toolbar>
        <ion-grid>
          <ion-row class="align-center" id="row">
            <ion-col size="9" class="d-flex">
              <ion-buttons class="mr-2" @click="$emit('close-modal')">
                <ion-icon size="small" :icon="arrowBackOutline"></ion-icon>
              </ion-buttons>
              <ion-label class="fs-4 fw-bold">{{ $t('delivery_date_time') }}</ion-label>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
    </ion-header>
    <ion-content v-if="viewEntered">
      <div>
        <div class="date mt-2 pa-2">
          <ion-label class="fs-3 text-uppercase" color="grey">{{ $t('select_delivery_date') }}</ion-label>
        </div>
        <ion-slides class="slides-cate" :options="slideOpts">
          <ion-slide
            v-for="(date, index) in deliveryDates"
            :key="index"
            :class="!date.isAvailable ? 'not-available' : ''"
          >
            <div
              :class="['d-flex flex-column align-center', !date.isAvailable ? 'disable-click' : '']"
              @click="selectDate(date.data)"
            >
              <div class="d-block date" :class="{ active: checkActive(date.data) }">
                <div class="pb-2 fs-3 text-grey">
                  {{ index === 0 ? $t('today') : formatDay(date.data) }}
                </div>
                <ion-text>
                  {{ formatDateMonth(date.data) }}
                </ion-text>
              </div>
              <ion-text class="fs-1">{{ !date.isAvailable ? $t('unavailable') : '' }}</ion-text>
            </div>
          </ion-slide>
        </ion-slides>
      </div>
      <ion-label class="fs-3 text-uppercase pt-4 pl-2" color="grey">{{
        $t('select_delivery_time')
      }}</ion-label>
      <ion-grid class="time pa-2">
        <ion-row>
          <ion-col>
            <ion-radio-group :value="selectedTime">
              <ion-item
                lines="none"
                class="ion-no-padding mb-1"
                v-model="selectedTime"
                v-for="(item, index) in deliveryTimes"
                :key="index"
                :disabled="item.isPassedEndTime"
                @click="selectTime(item)"
              >
                <div slot="start" class="d-flex flex-column">
                  <div class="d-flex flex-row">
                    <ion-label class="mr-3 mt-1" color="dark">
                      <div>{{ item.deliveryTimes }}</div>
                    </ion-label>
                    <div v-if="item.isPassedCutOffTime" class="d-flex flex-row box-label align-center fs-13">
                      <ion-icon class="mr-2" size="small" :icon="warningOutline"></ion-icon>
                      <ion-text>{{ $t('past_cut_off_time') }}</ion-text>
                    </div>
                  </div>
                  <ion-text
                    v-if="item.isPassedCutOffTime && selectedTime === item.deliveryTimes"
                    class="mt-2 text-primary"
                    >{{ $t('no_guaranted_delivery') }}</ion-text
                  >
                </div>
                <ion-radio
                  mode="ios"
                  slot="end"
                  :value="item.deliveryTimes"
                  :disabled="!isTimeSlotAvailable(selectedDate, item) && !item.isPassedCutOffTime"
                ></ion-radio>
              </ion-item>
            </ion-radio-group>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>

    <ion-footer v-if="viewEntered">
      <ion-toolbar class="pa-2">
        <ion-button color="primary" expand="block" @click="confirm()" :disabled="!selectedTime">
          <span>{{ $t('confirm') }}</span>
        </ion-button>
      </ion-toolbar>
    </ion-footer>
    <ion-loading
      :is-open="isOpenRef"
      cssClass="my-custom-class"
      :message="`${$t('please_wait')}. . .`"
      @didDismiss="setOpen(false)"
    >
    </ion-loading>
  </ion-page>
</template>

<script>
import dayjs from 'dayjs';
import { defineComponent, ref } from 'vue';

import { ACTIONS as ACTIONS_ORDER } from '@/modules/sale/store/order/actions';
import { useDateFormatter } from '@/usecases/global';
import { arrowBackOutline, warningOutline } from 'ionicons/icons';
import { createNamespacedHelpers } from 'vuex';

const { mapActions: mapActionsOrder } = createNamespacedHelpers('sale/order');

export default defineComponent({
  name: 'delivery-date-time',

  setup() {
    const slideOpts = {
      initialSlide: 0,
      speed: 400,
      slidesPerView: 'auto'
    };
    const { formatDateMonth, formatDay } = useDateFormatter();

    const user = ref(null);
    const deliveryTimes = ref([]);
    const selectedDate = ref(null);
    const selectedTime = ref(null);
    const isPassedCutOffTime = ref(false);
    const isOpenRef = ref(false);
    const setOpen = (state) => (isOpenRef.value = state);

    return {
      user,
      formatDateMonth,
      formatDay,
      deliveryTimes,
      selectedDate,
      selectedTime,
      isPassedCutOffTime,
      warningOutline,
      arrowBackOutline,
      slideOpts,
      dayjs,
      isOpenRef,
      setOpen
    };
  },
  props: {
    deliveryDate: {
      type: String,
      default: ''
    },
    deliveryTime: {
      type: String,
      default: ''
    },
    prevIsPassedCutOffTime: {
      type: Boolean,
      default: false
    },
    deliveryDates: {
      type: Array,
      default: function () {
        return [];
      }
    },
    checkIsTimeSlotAvailable: {
      type: Function,
      default: null
    }
  },
  inject: ['$storage'],

  emits: ['confirm', 'close-modal'],
  data() {
    return {
      viewEntered: false
    };
  },
  watch: {
    async selectedDate(selectedDate) {
      const selectedDeliveryTime = this.deliveryTimes.find((dt) => dt.deliveryTimes === this.selectedTime);
      if (
        !this.isTimeSlotAvailable(selectedDate, selectedDeliveryTime) &&
        selectedDate === this.deliveryDate
      ) {
        this.selectedTime = this.deliveryTimes.find((dt) =>
          this.isTimeSlotAvailable(selectedDate, dt)
        )?.deliveryTimes;
      }
      const getDeliveryTimesParams = {
        tenantId: this.user.tenant.id,
        selectedDate: this.selectedDate
      };
      this.deliveryTimes = await this[ACTIONS_ORDER.GET_DELIVERY_TIMES](getDeliveryTimesParams);
    }
  },
  computed: {
    checkActive() {
      return (date) => {
        if (this.selectedDate === date) {
          return true;
        }
      };
    }
  },

  created() {
    this.setOpen(true);
  },
  async mounted() {
    this.user = await this.$storage.getUser();
    this.selectedDate = this.deliveryDate;
    const getDeliveryTimesParams = {
      tenantId: this.user.tenant.id,
      selectedDate: this.selectedDate
    };
    this.deliveryTimes = await this[ACTIONS_ORDER.GET_DELIVERY_TIMES](getDeliveryTimesParams);
    this.isPassedCutOffTime = this.prevIsPassedCutOffTime;
    this.selectedTime = this.deliveryTime;
    this.viewEntered = true;
    this.setOpen(false);
  },
  methods: {
    ...mapActionsOrder([ACTIONS_ORDER.GET_DELIVERY_TIMES]),
    selectDate(date) {
      this.selectedDate = date;
      if (date !== this.deliveryDate) {
        this.selectedTime = null;
      }
    },
    selectTime(time) {
      this.selectedTime = time.deliveryTimes;
      this.isPassedCutOffTime = time.isPassedCutOffTime;
    },

    confirm() {
      this.$emit('confirm', {
        deliveryDate: this.selectedDate ? this.dayjs(this.selectedDate).format('YYYY-MM-DD') : '',
        deliveryTime: this.selectedTime,
        isPassedCutOffTime: this.isPassedCutOffTime
      });
    },
    isTimeSlotAvailable(selectedDate, deliveryTime) {
      return this.checkIsTimeSlotAvailable(selectedDate, deliveryTime?.deliveryTimes);
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/assets/css/modal/deliveryTime.scss';
</style>
