<template>
  <div></div>
</template>
<script>
import { defineComponent } from 'vue';
import { alertController } from '@ionic/vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    countModalCheckDuplicate: {
      type: Number,
      required: true
    }
  },
  emits: ['on-yes'],
  mounted() {
    this.presentAlertConfirm();
  },
  methods: {
    async presentAlertConfirm() {
      const alert = await alertController.create({
        mode: 'ios',
        cssClass: 'alert-double-order',
        header: this.title,
        message: this.subTitle,
        backdropDismiss: false,
        buttons: [
          {
            text: this.$t('yes_proceed_order'),
            handler: () => {
              this.$emit('on-yes', this.countModalCheckDuplicate);
            }
          },
          {
            text: this.$t('cancel')
          }
        ]
      });
      return alert.present();
    }
  }
});
</script>
