<template>
  <div class="cnt-modal">
    <div class="text-center d-flex flex-column align-center">
      <ion-icon color="warning" class="warning-icon my-4" :icon="warningOutline"></ion-icon>
      <ion-label class="fw-600 fs-3 mx-2">{{ $t('no_guaranted_delivery') }}!</ion-label>
    </div>
    <div class="my-3 mx-2 text-center mb-4 px-2">
      <ion-label>{{ $t('no_guaranted_message') }}?</ion-label>
    </div>
    <div class="line-horizontal"></div>

    <ion-button
      expand="block"
      fill="clear"
      class="w-100 fw-700 fs-15"
      size="small"
      color="primary"
      @click="$emit('changesDeliveryTime')"
      >{{ $t('change_delivery_time') }}</ion-button
    >
    <div class="line-horizontal"></div>
    <ion-button
      expand="block"
      fill="clear"
      class="w-100 fw-500 fs-15"
      size="small"
      color="primary"
      @click="$emit('createOrder')"
      >{{ $t('create_order') }}</ion-button
    >
  </div>
</template>
<script>
import { warningOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
export default defineComponent({
  setup() {
    return {
      warningOutline
    };
  },

  emits: ['on-no', 'on-yes']
});
</script>
<style lang="scss" scoped>
.cnt-modal {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.line-vertical {
  height: 40px;
  width: 1px;
  background-color: #e0e0e0;
  background: #e0e0e0;
}

.line-horizontal {
  height: 1px;
  width: 100%;
  background-color: #e0e0e0;
  background: #e0e0e0;
}

.warning-icon {
  height: 48px;
  width: 48px;
}
ion-button {
  text-transform: capitalize;
}
</style>
