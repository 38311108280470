<template>
  <ion-page>
    <!-- header -->
    <ion-header>
      <ion-toolbar class="">
        <ion-button slot="start" fill="clear" @click="$emit('close-modal')">
          <ion-icon slot="icon-only" size="small" :icon="close"></ion-icon>
        </ion-button>
        <ion-label class="fw-bold pr-3">{{
          isAddNew ? $t('add_new_address') : $t('change_address')
        }}</ion-label>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <div class="content w-100">
        <!-- map -->
        <div>
          <!-- input search -->
          <div class="d-flex">
            <input
              type="text"
              class="search-input has-suffix"
              ref="searchInput"
              :placeholder="$t('customerPage.enter_your_place')"
            />
            <span class="suffix d-flex">
              <span class="mr-2 d-flex align-center" @click="onSearch">
                <ion-icon :icon="searchOutline"></ion-icon>
              </span>
              <span class="search-slash position-relative"></span>
              <span class="ml-2 d-flex align-center" @click="clearInputSearch">
                <ion-icon :icon="closeOutline"></ion-icon>
              </span>
            </span>
          </div>

          <!-- gooogle map -->
          <div ref="mapDiv" style="width: 100%; height: 40vh" />
        </div>

        <ion-card class="card">
          <ion-card-content class="pa-0">
            <vee-form class="px-3" :validation-schema="addressSchema">
              <!-- country - state  -->
              <ion-row>
                <!-- country -->
                <ion-col size="6" class="pl-0">
                  <ion-item :class="['fs-2', errors && errors.country ? 'item-danger' : '']">
                    <ion-label position="floating">
                      {{ $t('country') }}
                    </ion-label>
                    <ion-input disabled v-model="country" name="country"></ion-input>
                  </ion-item>
                </ion-col>
                <!-- state -->
                <ion-col size="6" class="pr-0">
                  <ion-item :class="['fs-2', errors && errors.state ? 'item-danger' : '']">
                    <ion-label :color="errors && errors.state ? 'danger' : ''" position="floating">
                      {{ $t('state') }}
                    </ion-label>
                    <ion-input v-model="state" name="state"></ion-input>
                  </ion-item>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="6" class="pl-0">
                  <ion-text v-if="errors && errors.country" class="fs-12" color="danger">
                    <div class="pr-1 py-1">{{ $t(`${errors && errors.country}`) }}</div>
                  </ion-text>
                </ion-col>
                <ion-col size="6" class="pr-0">
                  <ion-text v-if="errors && errors.state" class="fs-12" color="danger">
                    <div class="pr-1 py-1">{{ $t(`${errors && errors.state}`) }}</div>
                  </ion-text>
                </ion-col>
              </ion-row>

              <!-- city - postal code  -->
              <ion-row>
                <!-- city -->
                <ion-col size="6" class="pl-0">
                  <ion-item :class="['fs-2', errors && errors.city ? 'item-danger' : '']">
                    <ion-label :color="errors && errors.city ? 'danger' : ''" position="floating">
                      {{ $t('city') }}
                    </ion-label>
                    <ion-input v-model="city" name="city"></ion-input>
                  </ion-item>
                </ion-col>
                <!-- state -->
                <ion-col size="6" class="pr-0">
                  <ion-item :class="['fs-2', errors && errors.postalCode ? 'item-danger' : '']">
                    <ion-label :color="errors && errors.postalCode ? 'danger' : ''" position="floating">
                      {{ $t('postal_code') }}
                    </ion-label>
                    <ion-input
                      type="number"
                      inputmode="numeric"
                      pattern="^(?:\(\d{3}\)|\d{3})[- . ]?\d{3}[- . ]?\d{4}$"
                      @keypress="onlyNumber"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                      v-model="postalCode"
                      name="postalCode"
                    >
                    </ion-input>
                  </ion-item>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="6" class="pl-0">
                  <ion-text v-if="errors && errors.city" class="fs-12" color="danger">
                    <div class="pr-1 py-1">{{ $t(`${errors && errors.city}`) }}</div>
                  </ion-text>
                </ion-col>
                <ion-col size="6" class="pr-0">
                  <ion-text v-if="errors && errors.postalCode" class="fs-12" color="danger">
                    <div class="pr-1 py-1">{{ $t(`${errors && errors.postalCode}`) }}</div>
                  </ion-text>
                </ion-col>
              </ion-row>

              <!-- street -->
              <ion-row>
                <ion-col size="12" class="px-0">
                  <ion-item class="fs-2">
                    <ion-label position="floating">
                      {{ $t('street') }}
                    </ion-label>
                    <ion-input :disabled="true" v-model="road" name="road"></ion-input>
                  </ion-item>
                </ion-col>
              </ion-row>

              <!-- builidng -->
              <ion-row>
                <ion-col size="12" class="px-0">
                  <ion-item class="fs-2">
                    <ion-label position="floating"> {{ $t('building_name') }} </ion-label>
                    <ion-input v-model="building" name="building"></ion-input>
                  </ion-item>
                </ion-col>
              </ion-row>

              <!-- floor - unit  -->
              <ion-row>
                <!-- floor -->
                <ion-col class="pl-0">
                  <ion-item class="fs-2">
                    <ion-label position="floating"> {{ $t('floor') }} </ion-label>
                    <ion-input v-model="floor" name="floor"></ion-input>
                  </ion-item>
                </ion-col>
                <div class="px-2 d-flex align-center">
                  <ion-icon :icon="removeOutline"></ion-icon>
                </div>
                <!-- unit -->
                <ion-col class="pr-0">
                  <ion-item class="fs-2">
                    <ion-label position="floating"> {{ $t('unit') }} </ion-label>
                    <ion-input v-model="unit" name="unit"></ion-input>
                  </ion-item>
                </ion-col>
              </ion-row>

              <!-- stall -->
              <ion-row>
                <ion-col size="12" class="px-0">
                  <ion-item class="fs-2">
                    <ion-label position="floating"> {{ $t('stall') }} </ion-label>
                    <ion-input v-model="stall" name="stall"></ion-input>
                  </ion-item>
                </ion-col>
              </ion-row>
            </vee-form>
          </ion-card-content>
        </ion-card>
      </div>
    </ion-content>

    <ion-footer>
      <ion-toolbar>
        <div class="pa-3">
          <div class="d-flex justify-space-between align-center">
            <ion-label>{{ $t('set_as_default_address') }}</ion-label>
            <ion-toggle v-model="isDefault"> </ion-toggle>
          </div>

          <div class="mt-3">
            <ion-button class="ma-0" expand="block" :disabled="isDisableBtn" @click="saveAddress"
              >{{ $t('save_address') }}
            </ion-button>
          </div>
          <div class="mt-3" v-if="!isAddNew">
            <ion-button
              class="ma-0"
              expand="block"
              color="danger"
              fill="clear"
              v-bind="propsDeleteAddressBtn()"
              @click="handleRemoveAddress"
            >
              <ion-icon slot="start" v-if="!isDisableDeleteBtn" :icon="trashOutline"></ion-icon>
              <ion-icon slot="start" v-if="isDisableDeleteBtn" :icon="informationCircleOutline"></ion-icon>
              {{ $t('address_btn_remove') }}
            </ion-button>
          </div>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>
<script>
/* eslint-disable no-undef */
import { removeAddress } from '@/modules/b2b/services/graphql';
import { getSpecialCharaterRegex } from '@/modules/b2b/services/libs/helper';
import { getAddressFormat } from '@/modules/sale/services/libs/helper';
import { presentAlert } from '@/modules/shared/utils';
import { Loader } from '@googlemaps/js-api-loader';
import {
  addOutline,
  businessOutline,
  close,
  closeOutline,
  informationCircleOutline,
  removeOutline,
  searchOutline,
  trashOutline
} from 'ionicons/icons';
import { defineComponent, ref } from 'vue';

import { apolloClient } from '@/main';
import { addressSchema, getDataFromPlace, setUpMap } from '@/modules/sale/services/libs/address';
import { ACTIONS as ACTIONS_ADDRESS } from '@/modules/sale/store/address/actions';
import { Form as VeeForm, useField, useForm } from 'vee-validate';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: mapGettersAddress, mapActions: mapActionsAddress } = createNamespacedHelpers(
  'sale/address'
);

export default defineComponent({
  props: {
    isAddNew: {
      type: Boolean,
      required: true
    },
    type: {
      type: Number,
      required: true
    },
    currentAddress: {
      type: Object,
      default: null
    }
  },

  components: {
    VeeForm
  },

  emits: ['close-modal'],

  setup() {
    const user = ref(null);
    const selectedCompany = ref(null);
    const input = ref(null);
    const placeData = ref(null);

    const key = process.env.VUE_APP_GOOGLEMAPS_KEY;
    const loader = new Loader({ apiKey: key, libraries: ['places'] });

    // Define a validation schema

    const { errors, validate } = useForm({
      validationSchema: addressSchema
    });

    // No need to define rules for fields
    const { value: country } = useField('country');
    const { value: state } = useField('state');
    const { value: city } = useField('city');
    const { value: road } = useField('road');
    const { value: building } = useField('building');
    const { value: floor } = useField('floor');
    const { value: unit } = useField('unit');
    const { value: postalCode } = useField('postalCode');
    const { value: stall } = useField('stall');
    const { value: isDefault } = useField('isDefault');
    const latlng = ref(null);

    return {
      user,
      selectedCompany,
      getAddressFormat,
      loader,
      input,

      // form
      placeData,
      addressSchema,
      errors,
      validate,
      country,
      state,
      city,
      road,
      building,
      floor,
      unit,
      postalCode,
      stall,
      isDefault,
      latlng,

      // icons
      close,
      businessOutline,
      addOutline,
      searchOutline,
      closeOutline,
      removeOutline,
      trashOutline,
      informationCircleOutline
    };
  },

  inject: ['$storage'],

  async mounted() {
    await this.loader.load();
    this.user = await this.$storage.getUser();
    this.selectedCompany = await this.$storage.getSelectedCompany();

    this.country = this.user.country.description;
    if (!this.isAddNew && this.currentAddress) {
      this.postalCode = this.currentAddress.postal_code;
      this.state = this.currentAddress.state;
      this.city = this.currentAddress.city;
      this.road = this.currentAddress.road;
      this.building = this.currentAddress.building;
      this.floor = this.currentAddress.floor_number;
      this.unit = this.currentAddress.unit;
      this.stall = this.currentAddress.stall;
      this.isDefault = this.currentAddress.is_default;
      this.latlng = this.currentAddress.latlng;
    }

    this.input = this.$refs.searchInput;

    setUpMap({
      defaultCenter: this.latlng,
      input: this.input,
      user: this.user,
      mapDiv: this.$refs.mapDiv,
      callback: this.getDataFromPlaceFn
    });
  },

  computed: {
    ...mapGettersAddress(['list']),
    isDisableBtn() {
      return !this.latlng;
    },
    isDisableDeleteBtn() {
      return this.currentAddress.is_default;
    }
  },

  methods: {
    ...mapActionsAddress([
      ACTIONS_ADDRESS.GET_ADDRESSES,
      ACTIONS_ADDRESS.ADD_ADDRESS,
      ACTIONS_ADDRESS.UPDATE_ADDRESS
    ]),

    async saveAddress() {
      const { valid } = await this.validate();

      let formData = {
        supplierId: this.selectedCompany.id,
        postalCode: this.postalCode,
        floorNumber: this.floor,
        countryId: this.user.country.id,
        addressTypeId: this.type,
        isDefault: this.isDefault ? true : false,
        latlng: this.latlng,
        road: this.road,
        building: this.building,
        unit: this.unit,
        stall: this.stall,
        city: this.city,
        state: this.state
      };

      if (valid) {
        if (this.isAddNew) {
          await this[ACTIONS_ADDRESS.ADD_ADDRESS](formData);
        } else {
          formData = {
            payload: formData,
            addressId: this.currentAddress.id
          };

          await this[ACTIONS_ADDRESS.UPDATE_ADDRESS](formData);
        }

        await this[ACTIONS_ADDRESS.GET_ADDRESSES]({
          supplierId: this.selectedCompany.id,
          active: true
        });
        this.$emit('close-modal');
      }
    },

    getDataFromPlaceFn(place) {
      const data = getDataFromPlace(place);
      this.placeData = data;
      this.state = data.state;
      this.city = data.city;
      this.postalCode = data.postalCode;
      this.road = data.road;
      this.latlng = data.latlng;
    },

    clearInputSearch() {
      this.input.value = null;
      this.input.focus();
    },
    onSearch() {
      if (this.input.value === '') {
        this.input.focus();
        return;
      }
      google.maps.event.trigger(this.input, 'focus', {});
      google.maps.event.trigger(this.input, 'keydown', {
        keyCode: 40, // arrow down
        stopPropagation: () => {}, // because these get called
        preventDefault: () => {}
      });
      google.maps.event.trigger(this.input, 'keydown', { keyCode: 13 }); // enter
    },
    onlyNumber(event) {
      const regex = new RegExp(getSpecialCharaterRegex());
      const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (regex.test(key) || [45, 46, 69, 101].includes(event.charCode)) {
        event.preventDefault();
        return;
      }
    },

    propsDeleteAddressBtn() {
      let props = {
        color: 'danger'
      };
      if (this.isDefault) {
        props.color = 'medium';
      }
      return props;
    },

    executeRemoveAddess() {
      apolloClient
        .mutate({
          mutation: removeAddress,
          variables: {
            addressId: this.currentAddress.id
          }
        })
        .then(async () => {
          await this[ACTIONS_ADDRESS.GET_ADDRESSES]({
            supplierId: this.selectedCompany.id,
            active: true
          });
          this.$emit('close-modal');
        });
    },

    async handleRemoveAddress() {
      try {
        if (this.isDefault) throw new Error(this.$t('address_unable_delete_default'));
        await presentAlert({
          mode: 'ios',
          message: this.$t('address_remove_confirm'),
          buttons: [
            {
              text: 'Ok',
              handler: () => {
                this.executeRemoveAddess();
              }
            },
            {
              text: 'Cancel'
            }
          ]
        });
      } catch (e) {
        presentAlert({
          mode: 'ios',
          header: '',
          message: '<img src="/assets/icon/payment/info-circle.svg" /><p/>' + e.message,
          buttons: ['OK']
        });
      }
    }
  }
});
</script>
<style src="./style.scss" lang="scss" scoped></style>
